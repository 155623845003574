import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/en-gb';

import { string, number, func, object, array } from 'prop-types';

import Slider from '../Slider/Slider';
import Gallery from '../Gallery/Gallery';
import ExpandableContent from '../_common/ExpandableContent/ExpandableContent';

import { BASE_URL } from '../../constants';

import './Exhibitions.scss';

const Exhibitions = ({
  id,
  selectedExhibitionId,
  setSelectedExhibitionId,
  name,
  title,
  // type,
  dateFrom,
  dateTo,
  description,
  subHeader,
  descriptionBlock2,
  subDescription,
  listOfPeople,
  pictures,
  pdfFiles,
  staticText,
  links,
  language,
  galleryFiles,
}) => {
  if (language) {
    if (language === 'da') {
      moment.locale('da');
    } else {
      moment.locale('en-gb');
    }
  }
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const ref = useRef();

  let pdfFilesMapped = [];
  if (pdfFiles.data != null) {
    pdfFilesMapped = pdfFiles.data.map((d) => ({ ...d.attributes, id: d.id }));
  }

  useEffect(() => {
    if (selectedExhibitionId === id && ref.current) {
      ref.current.scrollIntoView();
    }

    return () => {
      setSelectedExhibitionId(null);
    };
  }, [selectedExhibitionId, id, setSelectedExhibitionId]);

  const renderContent = () => (
    <>
      {description && (
        <h2
          className="exhibition__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {subHeader && (
        <div
          className="exhibition__subHeader"
          dangerouslySetInnerHTML={{ __html: subHeader }}
        />
      )}

      {descriptionBlock2 && (
        <h2
          className="exhibition__description"
          dangerouslySetInnerHTML={{ __html: descriptionBlock2 }}
        />
      )}

      {subDescription && (
        <div className="exhibition__list">
          <ExpandableContent
            collapseToggleTitle={staticText.exhibitionReadLess}
            expandToggleTitle={staticText.exhibitionReadMore}
          >
            <div className="exhibition__list-container">
              <h3
                className="exhibition__sub-description"
                dangerouslySetInnerHTML={{ __html: subDescription }}
              />
            </div>
          </ExpandableContent>
          {listOfPeople && (
            <h3
              className="exhibition__list-of-people"
              dangerouslySetInnerHTML={{ __html: listOfPeople }}
            />
          )}
        </div>
      )}
    </>
  );

  const renderHeader = () => (
    <>
      <div className="exhibition__title-container">
        {name && (
          <span
            className="exhibition__name"
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
        <span
          className="exhibition__title"
          dangerouslySetInnerHTML={{
            __html: title ? `${name ? ', ' : ''}${title}` : '',
          }}
        />
      </div>

      <div className="exhibition__subtitle-container">
        {dateFrom && (
          <span className="exhibition__date">
            {moment(new Date(dateFrom), 'YYYY-MM-DD').format('MMM D')}
            {dateTo &&
              ` — ${moment(new Date(dateTo), 'YYYY-MM-DD').format('MMM D, YYYY')}`}
          </span>
        )}
      </div>

      {!!pdfFilesMapped.length && (
        <div className="exhibition__pdf-files-container">
          {pdfFilesMapped.map((file) => (
            <h3 key={file.id} className="exhibition__pdf-file">
              <a
                href={`${BASE_URL}${file.url}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {file.caption || file.name.replace(/\.[^/.]+$/, '')}
              </a>
            </h3>
          ))}
        </div>
      )}

      {!!links.length && (
        <div className="exhibition__pdf-files-container">
          {links.map((link) => (
            <h3 key={link.id} className="exhibition__pdf-file">
              <a
                href={`${link.linkHref}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.linkTitle}
              </a>
            </h3>
          ))}
        </div>
      )}

      {galleryFiles?.data?.length && (
        <ExpandableContent
          collapseToggleTitle={staticText.galleryToggleButton}
          expandToggleTitle={staticText.galleryToggleButton}
          defaultIsOpen={isGalleryOpen}
          onChange={setIsGalleryOpen}
        >
          <Gallery galleryFiles={galleryFiles} staticText={staticText} />
        </ExpandableContent>
      )}
    </>
  );

  return (
    <div
      ref={ref}
      className={`exhibition ${isGalleryOpen ? 'exhibition-with-gallery' : 'exhibition-without-gallery'}`}
    >
      <div className="exhibition__header">{renderHeader()}</div>
      <div className="exhibition__content">{renderContent()}</div>
      <div className="exhibition__slider">
        <div className="exhibition__content-container">
          <div className="exhibition__slider-container">
            <Slider pictures={pictures} />
          </div>
        </div>
      </div>
    </div>
  );
};

Exhibitions.propTypes = {
  id: number,
  selectedExhibitionId: number,
  setSelectedExhibitionId: func.isRequired,
  name: string,
  title: string,
  // type: string,
  dateFrom: string,
  dateTo: string,
  description: string,
  subHeader: string,
  descriptionBlock2: string,
  subDescription: string,
  listOfPeople: string,
  pictures: object,
  pdfFiles: object,
  staticText: object,
  links: array,
  language: string,
  galleryFiles: object,
};

Exhibitions.defaultProps = {
  id: null,
  selectedExhibitionId: null,
  name: '',
  title: '',
  // type: '',
  dateFrom: '',
  dateTo: '',
  description: '',
  subHeader: '',
  descriptionBlock2: '',
  subDescription: '',
  listOfPeople: '',
  pictures: {},
  pdfFiles: {},
  staticText: {},
  links: [],
  language: '',
  galleryFiles: {},
};

export default Exhibitions;
