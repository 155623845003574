import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { string, number, func, object, array } from 'prop-types';

import Slider from '../Slider/Slider';

import { useMedia } from '../../utils/hooks';

import './Event.scss';
import { getFormattedTime } from '../../utils/getFormatedTime';

const Event = ({
  id,
  selectedEventId,
  setSelectedEventId,
  name,
  title,
  endTime,
  startTime,
  date,
  type,
  description,
  pictures,
  evtType,
  shouldExpandByDefault,
  link,
  subDescription,
  staticText,
}) => {
  const [isOpen, setIsOpen] = useState(shouldExpandByDefault);
  const [isOpenSubDescription, setIsOpenSubDescription] = useState(false);
  const ref = useRef();
  const isMobileVersion = useMedia(
    ['(min-width: 768px)', '(min-width: 280px)'],
    [false, true],
  );

  useEffect(() => {
    if (selectedEventId === id) {
      ref.current.scrollIntoView();
    }
    setSelectedEventId(null);
  });

  return (
    <div ref={ref} className="event">
      <div className="event__info">
        {isOpen && (
          <div className="event__title-container">
            {evtType && <span className="event__type">{evtType}</span>}
          </div>
        )}
        <div className="event__title-container">
          {name && (
            <span
              className="event__name"
              dangerouslySetInnerHTML={{ __html: name }}
            />
          )}
          <span
            className="event__title"
            dangerouslySetInnerHTML={{
              __html: title ? `${name ? ', ' : ''}${title}` : '',
            }}
          />
        </div>

        <div className="event__subtitle-container">
          {type && (
            <span
              className="event__subtitle"
              dangerouslySetInnerHTML={{ __html: `(${type})` }}
            />
          )}
          {date && (
            <span className="event__date">
              {`${moment(date, 'YYYY-MM-DD').format('MMM D, YYYY')}.${startTime && endTime ? ` ${getFormattedTime(startTime)} — ${getFormattedTime(endTime)}` : ''}`}
            </span>
          )}
        </div>

        <div className="event__btn-container">
          <button
            type="button"
            onClick={() => setIsOpen((prevState) => !prevState)}
            style={{
              position: 'absolute',
              top: '17px',
              left: 0,
              ...(!isOpen && { marginBottom: 0 }),
            }}
          >
            {isOpen ? (
              <span className="read-less" />
            ) : (
              <span className="read-more" />
            )}
          </button>
        </div>

        {isMobileVersion && !!link.length && isOpen && (
          <ul className="event__link-wrapper">
            {link
              .filter((item) => item.linkHref)
              .map((item) => (
                <li key={item.id} className="event__link">
                  <a href={item.linkHref}>{item.linkTitle || item.linkHref}</a>
                </li>
              ))}
          </ul>
        )}

        {isMobileVersion && isOpen ? (
          <div className="event__content-container">
            <div className="event__slider-container">
              <Slider pictures={pictures} orientation="horizontal" />
            </div>
          </div>
        ) : (
          <>
            {!!link.length && isOpen && (
              <ul>
                {link
                  .filter((item) => item.linkHref)
                  .map((item) => (
                    <li key={item.id} className="event__link">
                      <a href={item.linkHref}>
                        {item.linkTitle || item.linkHref}
                      </a>
                    </li>
                  ))}
              </ul>
            )}
            {description && isOpen && (
              <h2
                className="event__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {subDescription && isOpen && (
              <>
                <div className="event__list">
                  <div className="event__btn-container">
                    <button
                      type="button"
                      onClick={() =>
                        setIsOpenSubDescription((prevState) => !prevState)
                      }
                      style={{
                        ...(!isOpenSubDescription && { marginBottom: 0 }),
                      }}
                    >
                      {isOpenSubDescription ? (
                        <span className="read-less">
                          {staticText.eventReadLess}
                        </span>
                      ) : (
                        <span className="read-more">
                          {staticText.eventReadMore}
                        </span>
                      )}
                    </button>
                  </div>
                  {isOpenSubDescription && (
                    <div className="event__list-container">
                      <h3
                        className="event__sub-description"
                        dangerouslySetInnerHTML={{ __html: subDescription }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {isOpen && (
        <div className="event__content">
          {isMobileVersion ? (
            <>
              {description && (
                <h2
                  className="event__description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {subDescription && isOpen && (
                <>
                  <div className="event__list">
                    <div className="event__btn-container">
                      <button
                        type="button"
                        onClick={() =>
                          setIsOpenSubDescription((prevState) => !prevState)
                        }
                        style={{
                          ...(!isOpenSubDescription && { marginBottom: 0 }),
                        }}
                      >
                        {isOpenSubDescription ? (
                          <span className="read-less">
                            {staticText.eventReadLess}
                          </span>
                        ) : (
                          <span className="read-more">
                            {staticText.eventReadMore}
                          </span>
                        )}
                      </button>
                    </div>
                    {isOpenSubDescription && (
                      <div className="event__list-container">
                        <h3
                          className="event__sub-description"
                          dangerouslySetInnerHTML={{ __html: subDescription }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="event__content-container">
              <div className="event__slider-container">
                <Slider pictures={pictures} orientation="horizontal" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Event.propTypes = {
  id: number,
  selectedEventId: number,
  setSelectedEventId: func.isRequired,
  name: string,
  title: string,
  type: string,
  date: string,
  endTime: string,
  startTime: string,
  evtType: string,
  description: string,
  subDescription: string,
  pictures: object,
  link: array,
};

Event.defaultProps = {
  id: null,
  selectedEventId: null,
  name: '',
  title: '',
  type: '',
  date: '',
  description: '',
  subDescription: '',
  pictures: {},
  endTime: '',
  startTime: '',
  link: [],
};

export default Event;
